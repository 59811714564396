import * as React from "react";
import { Typography } from "@mui/material";
import FAQ from "./FAQ";
import FAQSection from "./FAQSection";

export default function FAQPoliceSection() {
  return (
    <FAQSection title="Police">
      <FAQ title="Will the police take my results seriously?">
        <Typography variant="body2">
          The police are aware that many survivors are not reporting through the
          CJS.
        </Typography>
        <br />
        <Typography variant="body2">
          The police will consider any relevant information for a case. If you
          choose to report to them later, you would download your time-stamped
          testimony and DNA results and pass them over to your contact at the
          police. The police would also request the frozen sample to run their
          own analysis on.
        </Typography>
      </FAQ>
      <FAQ title="What about false allegations?">
        <Typography variant="body2">
          There’s nothing accusatory about enough. We will not be naming any
          perpetrators.
        </Typography>
        <br />
        <Typography variant="body2">
          If you pass on your report to the police, they will scrutinise it (as
          they would any evidence.)
        </Typography>
        <br />
        <Typography variant="body2">
          To think someone could swab a glass and then end up in prison is a
          disservice to the rigor and robustness of the police and our justice
          system.
        </Typography>
        <br />
        <Typography variant="body2">
          The issue has been completely sensationalised: a man is 230x more
          likely to be raped than falsley accused - we are solving the more
          pervasive problem!
        </Typography>
      </FAQ>
      <FAQ title="Can I report to the police and enough?">
        <Typography variant="body2">
          If you want to report to the police, you should go there first because
          a full sexual assault exam conducted by the police or at a SARC is
          more extensive and designed to facilitate a criminal investigation.
        </Typography>
        <br />
        <Typography variant="body2">
          You can also report with enough afterwards, and access our recovery
          content.
        </Typography>
      </FAQ>
    </FAQSection>
  );
}
