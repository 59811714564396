import React from "react";

import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import PaddedBox from "./PaddedBox";

import PageContainer from "./PageContainer";

export default function ContactPage() {
  return (
    <PageContainer>
      <PaddedBox>
        <Typography variant="h5">
          Please contact either of us with all questions and comments:
        </Typography>
        <br />
        <Typography variant="body1">
          Katie White:{" "}
          <Link to="mailto:katie@myenough.com">katie@myenough.com</Link>
        </Typography>
        <Typography variant="body1">
          Tom Allchurch:{" "}
          <Link to="mailto:tom@myenough.com">tom@myenough.com</Link>
        </Typography>
        <br />
        <Typography variant="body1">Co-Founders</Typography>
      </PaddedBox>
    </PageContainer>
  );
}
